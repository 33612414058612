/**
 *  Sets styles on the top navbar to indicate the current page.
 * @param linkID the ID of the link in the top navbar that goes to the current page.
 */
export function setCurrentPage(linkID) {
  var link=document.getElementById(linkID);
  if (link)
    link.className = 'current';
  else
    setTimeout(function() {setCurrentPage(linkID)}, 10); // allow the page to finish loading
};

